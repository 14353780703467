import { Link } from 'react-router-dom';

export default function FreeClassBtn() {
    return (
        <div className="mt-10 flex items-center gap-x-6">
          <Link
            to="/get-free-class"
            style={{ backgroundColor: '#ff6346' }}
            className="rounded-md bg-indigo-600 px-6 py-4 text-lg font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Get Your First Class Free
          </Link>
      </div>
    )
}