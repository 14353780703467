import Header from "../../../common/Header";
import SubjectDetail from "../../../common/SubjectDetail";
import ReachAdvisorBanner from "../../../common/ReachAdvisorBanner";
import Footer from "../../../common/Footer";

import ScratchLevelA from "./ScratchLevelA.json";
import ScratchLevelB from "./ScratchLevelB.json";


export default function Scratch() {
    const courses = [ScratchLevelA, ScratchLevelB]
    const  content = {
        "title": "What is Scratch?",
        "description": "Scratch is an opportunity for beginners to learn the basics of coding using MIT’s Scratch language. Your learner will work one on one with a Mathsforce instructor to create games that will show off their creativity and ideas. This is a perfect course for someone new to coding!",
        "backgroundColor": "#eaf7ff",
        "image": "scratch.png",
        "courses": courses
    }
    const courseTitle = 'Start our Scratch Program'
    const courseDescription = 'Scratch coding lays the foundation for young students to progress to more advanced languages like Python or Java. Choose the right course based on your child’s age and experience—start with Scratch Level 1 for beginners or Level 2 for those with prior coding experience.'

    return (
        <>
            <Header/>
            <SubjectDetail content = {content} courseTitle={courseTitle} courseDescription={courseDescription}  />
            <ReachAdvisorBanner/>
            <Footer/>
        </>
    )
}