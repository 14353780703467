import React, { useState, useEffect } from 'react';
import { tutorApi } from './tutorApi';

const BankDetails = () => {
  const [paymentType, setPaymentType] = useState('PAYPAL');
  const [accountHolderName, setAccountHolderName] = useState('');
  const [emailId, setEmailId] = useState('');
  const [upiId, setUpiId] = useState('');
  const [message, setMessage] = useState(null);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchBankDetails = async () => {
      try {
        const response = await tutorApi.getBankDetails();
        if (response.data) {
          setPaymentType(response.data.payment_type || 'PAYPAL');
          setAccountHolderName(response.data.account_holder_name || '');
          setEmailId(response.data.email_id || '');
          setUpiId(response.data.upi_id || '');
        }
      } catch (error) {
        console.error('Failed to fetch bank details:', error);
      }
    };

    fetchBankDetails();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await tutorApi.createBankDetails({
        payment_type: paymentType,
        account_holder_name: accountHolderName,
        email_id: paymentType === 'PAYPAL' ? emailId : '',
        upi_id: paymentType === 'UPI' ? upiId : ''
      });
      setMessage(response.message || 'Bank details updated successfully');
      setIsError(false);
    } catch (error) {
      setMessage(error.response?.data?.message || 'An error occurred while updating bank details');
      setIsError(true);
    }
  };

  return (
    <div className="w-full">
      {message && (
        <div className={`p-4 mb-4 rounded-md ${isError ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'}`}>
          {message}
        </div>
      )}
      <div className="flex items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-800 mr-3">Bank Details</h2>
      </div>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Payment Type
          </label>
          <div className="flex space-x-4">
            <label className="inline-flex items-center">
              <input
                type="radio"
                value="PAYPAL"
                checked={paymentType === 'PAYPAL'}
                onChange={() => setPaymentType('PAYPAL')}
                className="form-radio"
              />
              <span className="ml-2">PayPal</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                value="UPI"
                checked={paymentType === 'UPI'}
                onChange={() => setPaymentType('UPI')}
                className="form-radio"
              />
              <span className="ml-2">UPI (only India)</span>
            </label>
          </div>
        </div>
        <div>
          <label htmlFor="accountHolderName" className="block text-sm font-medium text-gray-700 mb-1">
            Account Holder Name
          </label>
          <input
            type="text"
            id="accountHolderName"
            value={accountHolderName}
            onChange={(e) => setAccountHolderName(e.target.value)}
            required
            className="w-1/2 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        {paymentType === 'PAYPAL' && (
          <div>
            <label htmlFor="emailId" className="block text-sm font-medium text-gray-700 mb-1">
              Email ID (Registered with PayPal)
            </label>
            <input
              type="email"
              id="emailId"
              value={emailId}
              onChange={(e) => setEmailId(e.target.value)}
              required
              className="w-1/2 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        )}
        {paymentType === 'UPI' && (
          <div>
            <label htmlFor="upiId" className="block text-sm font-medium text-gray-700 mb-1">
              UPI ID
            </label>
            <input
              type="text"
              id="upiId"
              value={upiId}
              onChange={(e) => setUpiId(e.target.value)}
              required
              className="w-1/2 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        )}
        <button
          type="submit"
          className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          Save Changes
        </button>
      </form>
    </div>
  );
};

export default BankDetails;
