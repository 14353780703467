import Header from "../../common/Header"
import CourseDetails from "../../common/CourseDetails"
import ReachAdvisorBanner from "../../common/ReachAdvisorBanner"
import Footer from "../../common/Footer"
import courseData from "./Cpp.json"

export default function Cpp() {
    
    return (
        <>
            <Header/>
            <CourseDetails courseData = {courseData}/>
            <ReachAdvisorBanner/>
            <Footer/>
        </>
    )
}