import React, { useState, useEffect } from 'react';

const CreateInvoiceForm = ({ students, onSubmit, initialData = null }) => {
  const [formData, setFormData] = useState({
    lessonPackId: '',
    date: '',
    duration: '',
    notes: ''
  });

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
    }
  }, [initialData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const selectedClass = students.find(c => c.id === parseInt(formData.lessonPackId));
      if (!selectedClass) {
        throw new Error('No class selected');
      }

      const invoiceData = {
        lesson_pack_id: parseInt(formData.lessonPackId),
        date: formData.date,
        duration: parseFloat(formData.duration),
        notes: formData.notes,
        subject: selectedClass.subject
      };

      await onSubmit(invoiceData);
    } catch (error) {
      console.error('Error saving invoice:', error);
      // You might want to show an error message to the user here
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block mb-2">Lesson Pack ID</label>
          <select
            name="lessonPackId"
            value={formData.lessonPackId}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            required
          >
            <option value="">Select Student & Subject</option>
            {students.map((student) => (
              <option key={student.id} value={student.id}>
                {student.studentName} - {student.subject} ({student.remainingHours} hours remaining)
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block mb-2">Date</label>
          <input
            type="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div>
          <label className="block mb-2">Duration (Hours)</label>
          <input
            type="number"
            name="duration"
            value={formData.duration}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            required
            step="0.5"
            min="0.5"
          />
        </div>
        <div className="col-span-2">
          <label className="block mb-2">Session Notes</label>
          <textarea
            name="notes"
            value={formData.notes}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            required
            rows="3"
          />
        </div>
      </div>
      <button
        type="submit"
        className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
      >
        {initialData ? 'Update Invoice' : 'Create Invoice'}
      </button>
    </form>
  );
};

export default CreateInvoiceForm; 