import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return <div>Loading...</div>; // Or any loading indicator
  }

  if (!isAuthenticated) {
    // If not authenticated, redirect to login
    return <Navigate to="/tutor/login" state={{ from: location }} replace />;
  }

  // If authenticated, render the children
  return children;
};

export default PrivateRoute;