import Footer from "../../../common/Footer"
import Header from "../../../common/Header"
import ReachAdvisorBanner from "../../../common/ReachAdvisorBanner"
import SubjectDetail from "../../../common/SubjectDetail"

export default function CollegeMath() {
    const content = {
        "title": "College Math Courses",
        "description": "Our college math courses are common-core aligned and designed to help students problem-solve, become more confident and enjoy learning math.",
        "backgroundColor": "#eaf7ff",
        "image": "steven-aguilar-gjzLLrAn1LM-unsplash.jpg",
        "courses": []
    }
    const courseTitle = 'Our College Level Math Courses'
    const courseDescription = 'For college-level math, our tutors customize the curriculum to match each student`s unique skill level and learning needs. This personalized approach ensures that the material is neither too challenging nor too simple, allowing students to progress at their own pace and gain a deeper understanding of complex mathematical concepts.'

    return (
        <>
            <Header/>
            <SubjectDetail content = {content} courseTitle={courseTitle} courseDescription={courseDescription}  />
            <ReachAdvisorBanner/>
            <Footer/>
        </>
    )
}