import Course from './common/Course';

import PreAlgebraA from './courses/math/middle/PreAlgebraA.json';
import PythonLevelA from './courses/coding/python/PythonLevelA.json';
import AiLevelA from './courses/coding/python/AiLevelA.json';

export default function PopularCourses() {

  const courses = [PreAlgebraA, PythonLevelA, AiLevelA];
  const title = "Our Most Popular Courses"
  const description = "We’ve taught over 10,000 students, and these are our families’ favorite Mathsforce courses. Learn more about each course below and find the perfect one for your child."

  return (
    <Course courses = {courses} title = {title} description = {description} />
  )
}
