import Footer from "../../../common/Footer"
import Header from "../../../common/Header"
import ReachAdvisorBanner from "../../../common/ReachAdvisorBanner"
import SubjectDetail from "../../../common/SubjectDetail"

import PreAlgebraA from "./PreAlgebraA.json"
import PreAlgebraB from "./PreAlgebraB.json"
import Algebra1A from "./Algebra1A.json"
import Algebra1B from "./Algebra1B.json"
import GeometryA from "./GeometryA.json"
import GeometryB from "./GeometryB.json"


export default function MiddleSchoolMath() {
    const courses = [PreAlgebraA, PreAlgebraB, Algebra1A, Algebra1B, GeometryA, GeometryB]
    const  content = {
        "title": "Middle School Math Courses",
        "description": "Our middle school math courses are common-core aligned and designed to help students problem-solve, become more confident and enjoy learning math.",
        "backgroundColor": "#eaf7ff",
        "image": "ben-white-83tkHLPgg2Q-unsplash.jpg",
        "courses": courses
    }
    console.log(content)
    // const content = courseData.middle_school_math
    const courseTitle = 'Our Middle School Level Math Courses'
    const courseDescription = 'Find the course that best aligns with where your child is in school. After your Advisor call, we’ll schedule a placement class to make sure your child is at the right level.'

    return (
        <>
            <Header/>
            <SubjectDetail content = {content} courseTitle={courseTitle} courseDescription={courseDescription}  />
            <ReachAdvisorBanner/>
            <Footer/>
        </>
    )
}