import React from 'react';
import Header from "../../../common/Header";
import SubjectDetail from "../../../common/SubjectDetail";
import ReachAdvisorBanner from "../../../common/ReachAdvisorBanner";
import Footer from "../../../common/Footer";

import YoungLearnersA from "./YoungLearnersA.json";
import YoungLearnersB from "./YoungLearnersB.json";
import JuniorScholarsA from "./JuniorScholarsA.json";
import JuniorScholarsB from "./JuniorScholarsB.json";

export default function ElementarySchoolMath() {
    const courses = [YoungLearnersA, YoungLearnersB, JuniorScholarsA, JuniorScholarsB]
    const  content = {
        "title": "Elementary School Math Courses",
        "description": "Our elementary school math courses are designed to help students learn to love math, understand themselves and grow in their math confidence and skills.",
        "backgroundColor": "#eaf7ff",
        "image": "kids-giggle-on-steps.jpg",
        "courses": courses
    }
    const courseTitle = 'Our Elementary School Level Math Courses'
    const courseDescription = 'Find the course that best aligns with where your child is in school. After your Advisor call, we’ll schedule a placement class to make sure your child is at the right level.'

    return (
        <>
            <Header/>
            <SubjectDetail content = {content} courseTitle={courseTitle} courseDescription={courseDescription}  />
            <ReachAdvisorBanner/>
            <Footer/>
        </>
    )
}