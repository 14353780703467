import { ArrowRightCircleIcon } from '@heroicons/react/20/solid'
import React from 'react';
import FreeClassBtn from './common/FreeClassBtn';


const features = [
  {
    name: 'Instructors from top U.S universities.',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Mentorship for academic growth.',
    description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Curriculum that fits your needs.',
    description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.',
    icon: ArrowRightCircleIcon,
  },
]


export default function Hero() {
    return (
      <div className="relative" style={{ backgroundColor: '#CEEFE0' }}>
        <div className="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8">
          <div className="px-6 pb-24 pt-10 sm:pb-32 lg:col-span-7 lg:px-0 lg:pb-56 lg:pt-48 xl:col-span-6">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <h1 className="mt-24 text-4xl font-bold tracking-tight text-gray-900 sm:mt-10 sm:text-5xl">
                Crafting unique learning paths for the curious
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-600">
              Mathsforce connects your child with a top-tier instructor who will provide personalized 1:1 online classes in STEM subjects such as Coding, Math, and more.
              </p>

              <div className="mt-10 flex items-center gap-x-6">
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {features.map((feature) => (
                    <div key={feature.name} className="relative pl-9">
                      <dt className="inline font-semibold text-gray-900">
                        <feature.icon aria-hidden="true" className="absolute left-1 top-1 h-5 w-5 text-indigo-600" />
                        {feature.name}
                      </dt>{' '}
                      {/* <dd className="inline">{feature.description}</dd> */}
                    </div>
                  ))}
                </dl>
              </div>

              <FreeClassBtn/>
              
            </div>
          </div>
          <div className="relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
            {/* <img
              alt=""
              src="https://images.unsplash.com/photo-1498758536662-35b82cd15e29?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2102&q=80"
              className="aspect-[3/2] w-full bg-gray-50 object-cover lg:absolute lg:inset-0 lg:aspect-auto lg:h-full"
            /> */}
            {/* <img
              alt=""
              src={`${process.env.PUBLIC_URL}/female-taking-notes-and-browsing-laptop.jpg`}
              className="aspect-[3/2] w-full bg-gray-50 object-cover lg:absolute lg:inset-0 lg:aspect-auto lg:h-full"
            /> */}
            {/* <img
              alt=""
              src={`${process.env.PUBLIC_URL}/woman-works-on-her-laptop-outdoors-in-green-lawn-chair.jpg`}
              className="aspect-[3/2] w-full bg-gray-50 object-cover lg:absolute lg:inset-0 lg:aspect-auto lg:h-full"
            /> */}
             <img
              alt=""
              src={`${process.env.PUBLIC_URL}/hero-degree.jpg`}
              className="aspect-[3/2] w-full bg-gray-50 object-cover lg:absolute lg:inset-0 lg:aspect-auto lg:h-full"
            />
          </div>
        </div>
      </div>
    )
  }
