const steps = [
  {
    id: 1,
    title: 'Sign Up',
    description: 'At the time of signup, we’ll ask you for more details about your student. You can make a course selection or compare courses with a Mathsforce Advisor.',
    step_no: "Step One",
    icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6 text-green-500 w-16 h-16"><path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" /></svg>
  },
  {
    id: 2,
    title: 'Instructor Matching',
    description: 'Your child will be matched with their ongoing instructor to continue with weekly 50-minute on-on-one classes. You’ll have an opportunity at sign-up to make any instructor requests.',
    step_no: "Step Two",
    icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6 text-green-500 w-16 h-16"><path d="M8.25 10.875a2.625 2.625 0 1 1 5.25 0 2.625 2.625 0 0 1-5.25 0Z" /><path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.125 4.5a4.125 4.125 0 1 0 2.338 7.524l2.007 2.006a.75.75 0 1 0 1.06-1.06l-2.006-2.007a4.125 4.125 0 0 0-3.399-6.463Z" clip-rule="evenodd" /></svg>
  },
  {
    id: 3,
    title: 'Your First Class',
    description: 'In the placement class, our instructors get to know your child, go over Mathsforce tools and resources, and assess their level in the specific subject. They will then confirm the course selection.',
    step_no: "Step Three",
    icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6 text-green-500 w-16 h-16"><path d="M4.5 4.5a3 3 0 0 0-3 3v9a3 3 0 0 0 3 3h8.25a3 3 0 0 0 3-3v-9a3 3 0 0 0-3-3H4.5ZM19.94 18.75l-2.69-2.69V7.94l2.69-2.69c.944-.945 2.56-.276 2.56 1.06v11.38c0 1.336-1.616 2.005-2.56 1.06Z" /></svg>  
  }
];


export default function GettingStarted() {
  return (
    <div className="bg-white py-24 sm:py-32 " style={{ backgroundColor: '#ecfbd5' }}>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">How Mathsforce Works</h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
          Mathsforce schedules classes based on your schedule, and we’ll use all the details you provide on instructor preferences and your child’s personality and learning style to match them with a great instructor!

After class, you can find progress reports and instructor feedback in the Mathsforce portal. We handle all logistics so your child can focus on enjoying learning.
          </p>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {steps.map((step) => (
            <article key={step.id} className="bg-white flex flex-col items-start justify-between rounded-xl">
              
              <div className="relative w-full flex items-center justify-between px-6 my-4">
                <div className="mt-12 flex items-center gap-x-6 text-sm">
                  <div className="relative z-10 rounded-full bg-gray-100 px-4 py-2 font-medium text-gray-600 hover:bg-gray-100">
                    {step.step_no}
                  </div>
                </div>
                {step.icon}
              </div>
              

              <div className="max-w-xl m-6">
                <div className="group relative">
                  <h3 className="mt-4 text-2xl  leading-6 text-gray-900">
                    <div>
                      <span className="absolute inset-0" />
                      {step.title}
                    </div>
                  </h3>
                  <p className="mt-5 text-lg leading-6 text-gray-500">{step.description}</p>
                </div>
              </div>
              
            </article>
          ))}
        </div>
      </div>
    </div>
  )
}
