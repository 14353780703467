import Footer from "./common/Footer";
import GettingStarted from "./GettingStarted";
import Header from "./common/Header";
import Hero from "./Hero";
import ParentTestimonial from "./ParentTestimonial";
import PopularCourses from "./PopularCourses";
import ReachAdvisorBanner from "./common/ReachAdvisorBanner";

export default function HomePage() {
    return (
        <>
            <Header/>
            <Hero/>
            <ParentTestimonial/>
            <GettingStarted/>
            <PopularCourses/>
            <ReachAdvisorBanner/>
            <Footer/>
        </>
    )
}