import Header from "../../../common/Header";
import SubjectDetail from "../../../common/SubjectDetail";
import ReachAdvisorBanner from "../../../common/ReachAdvisorBanner";
import Footer from "../../../common/Footer";

import PythonLevelA from "./PythonLevelA.json";
import PythonLevelB from "./PythonLevelB.json";
import PythonLevelC from "./PythonLevelC.json";
import PyGame from "./PyGame.json";
import DataScience from "./DataScience.json";
import AiLevelA from "./AiLevelA.json";
import AiLevelB from "./AiLevelB.json";


export default function Python() {
    const courses = [PythonLevelA, PythonLevelB, PythonLevelC, PyGame, DataScience, AiLevelA, AiLevelB]
    const  content = {
        "title": "What is Python?",
        "description": "Python is a programming language that can be applied to multiple real world situations. If a problem utilizes a lot of big data, artificial intelligence, web development/web applications, or modeling, Python is one of the go-to languages used to create deliverable results.",
        "backgroundColor": "#eaf7ff",
        "image": "python.jpg",
        "courses": courses
    }
    const courseTitle = 'Start our Python Program'
    const courseDescription = 'Pick your course based on your student’s age and experience level. Choose between Python A or B based on your child’s previous coding experience.'

    return (
        <>
            <Header/>
            <SubjectDetail content = {content} courseTitle={courseTitle} courseDescription={courseDescription}  />
            <ReachAdvisorBanner/>
            <Footer/>
        </>
    )
}