import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import tutorApi from './tutorApi'

export default function LeadDetails({ isOpen, setIsOpen, lead, onActionComplete }) {
  const statusColors = {
    ACCEPTED: 'bg-green-100 text-green-800',
    PENDING: 'bg-yellow-100 text-yellow-800',
    REJECTED: 'bg-red-100 text-red-800',
    COMPLETED: 'bg-blue-100 text-blue-800',
    CANCELLED: 'bg-gray-100 text-gray-800',
    SCHEDULED: 'bg-purple-100 text-purple-800',
    EXPIRED: 'bg-orange-100 text-orange-800',
    MISSED: 'bg-pink-100 text-pink-800'
  }

  const getStatusDisplay = (status) => {
    // Convert status to Title Case for display
    return status?.toLowerCase()
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
  }

  const handleAccept = async () => {
    try {
      await tutorApi.acceptLead(lead.id);
      setIsOpen(false);
      onActionComplete(); // This will trigger the reload of opportunities
    } catch (error) {
      console.error('Error accepting lead:', error);
      // You might want to show an error toast/message here
    }
  };

  const handleDecline = async () => {
    try {
      await tutorApi.declineLead(lead.id);
      setIsOpen(false);
      onActionComplete(); // This will trigger the reload of opportunities
    } catch (error) {
      console.error('Error declining lead:', error);
      // You might want to show an error toast/message here
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setIsOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Lead Details
                    </Dialog.Title>
                    <div className="mt-4 space-y-4">
                      <div>
                        <h4 className="text-sm font-medium text-gray-500">Status</h4>
                        <span className={`mt-1 inline-flex rounded-full px-2.5 py-0.5 text-xs font-semibold ${statusColors[lead?.status] || 'bg-gray-100 text-gray-800'}`}>
                          {getStatusDisplay(lead?.status)}
                        </span>
                      </div>
                      <div>
                        <h4 className="text-sm font-medium text-gray-500">Posted</h4>
                        <p className="mt-1 text-sm text-gray-900">{lead?.time_since_posted}</p>
                      </div>
                      <div>
                        <h4 className="text-sm font-medium text-gray-500">Subject</h4>
                        <p className="mt-1 text-sm text-gray-900">{lead?.subject}</p>
                      </div>
                      <div>
                        <h4 className="text-sm font-medium text-gray-500">Demo Time</h4>
                        <p className="mt-1 text-sm text-gray-900">{lead?.dateTime}</p>
                      </div>
                      <div>
                        <h4 className="text-sm font-medium text-gray-500">Grade</h4>
                        <p className="mt-1 text-sm text-gray-900">{lead?.grade}</p>
                      </div>
                      <div>
                        <h4 className="text-sm font-medium text-gray-500">Country</h4>
                        <p className="mt-1 text-sm text-gray-900">{lead?.country}</p>
                      </div>
                      <div>
                        <h4 className="text-sm font-medium text-gray-500">Demo Topic</h4>
                        <p className="mt-1 text-sm text-gray-900">{lead?.demoTopic}</p>
                      </div>
                      <div>
                        <h4 className="text-sm font-medium text-gray-500">Notes</h4>
                        <p className="mt-1 text-sm text-gray-900">{lead?.notes}</p>
                      </div>
                      {lead?.attachments && lead.attachments.length > 0 && (
                        <div>
                          <h4 className="text-sm font-medium text-gray-500">Attachments</h4>
                          <div className="mt-2 space-y-2">
                            {lead.attachments.map((attachment, index) => (
                              <a
                                key={index}
                                href={attachment.url}
                                download
                                className="inline-flex items-center gap-x-2 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              >
                                <ArrowDownTrayIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                {attachment.name}
                              </a>
                            ))}
                          </div>
                        </div>
                      )}
                      {lead?.status === 'new' && (
                        <div className="mt-6 flex gap-3 justify-end border-t pt-4">
                          <button
                            type="button"
                            onClick={handleDecline}
                            className="rounded-md bg-white px-3.5 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          >
                            Decline
                          </button>
                          <button
                            type="button"
                            onClick={handleAccept}
                            className="rounded-md bg-indigo-600 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            Accept
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
} 