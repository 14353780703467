import { useState, useEffect } from 'react';
import tutorApi from './tutorApi';
import LeadDetails from './LeadDetails';

export default function Opportunity() {
  const [allLeads, setAllLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedLead, setSelectedLead] = useState(null);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const statusConfig = {
    new: { text: 'New', bgColor: 'bg-green-50', textColor: 'text-green-700', ringColor: 'ring-green-600/20' },
    allotted: { text: 'Allotted', bgColor: 'bg-blue-50', textColor: 'text-blue-700', ringColor: 'ring-blue-600/20' },
    accepted: { text: 'Accepted', bgColor: 'bg-purple-50', textColor: 'text-purple-700', ringColor: 'ring-purple-600/20' },
    declined: { text: 'Declined', bgColor: 'bg-red-50', textColor: 'text-red-700', ringColor: 'ring-red-600/20' },
    missed: { text: 'Missed', bgColor: 'bg-yellow-50', textColor: 'text-yellow-700', ringColor: 'ring-yellow-600/20' }
  };

  const fetchLeads = async () => {
    try {
      setIsLoading(true);
      const data = await tutorApi.getLeads();
      
      // Combine all lead arrays and add status property
      const processedLeads = [
        ...data.new_leads.map(lead => ({ ...lead, status: 'new' })),
        ...data.accepted_leads.map(lead => ({ ...lead, status: 'accepted' })),
        ...data.allotted_leads.map(lead => ({ ...lead, status: 'allotted' })),
        ...data.declined_leads.map(lead => ({ ...lead, status: 'declined' })),
        ...data.missed_leads.map(lead => ({ ...lead, status: 'missed' }))
      ];
      
      setAllLeads(processedLeads);
    } catch (err) {
      setError('Failed to fetch leads');
      console.error('Error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLeads();
  }, []);

  const handleActionComplete = () => {
    fetchLeads();
  };

  if (isLoading) {
    return <div className="p-4">Loading...</div>;
  }

  if (error) {
    return <div className="p-4 text-red-600">{error}</div>;
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Tutoring Opportunities</h1>
          <p className="mt-2 text-sm text-gray-700">
            You will get to see all the tutoring opportunities for you right here.
          </p>
        </div>
        {/* <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Add user
          </button>
        </div> */}
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Posted
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Status
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Subject
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Demo Time
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">View Details</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {allLeads.map((person) => (
                  <tr key={person.id}>
                    <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                      <div className="flex items-center">
                        <div className="ml-4">
                          <div className="mt-1 text-gray-500">{person.time_since_posted}</div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <span className={`inline-flex items-center rounded-md ${statusConfig[person.status].bgColor} px-2 py-1 text-xs font-medium ${statusConfig[person.status].textColor} ring-1 ring-inset ${statusConfig[person.status].ringColor}`}>
                        {statusConfig[person.status].text}
                      </span>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <div className="text-gray-900">{person.subject}</div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{person.dateTime}</td>
                    <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <button
                        onClick={() => {
                          setSelectedLead(person);
                          setIsDetailsOpen(true);
                        }}
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        View Details<span className="sr-only">, {person.name}</span>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <LeadDetails
        isOpen={isDetailsOpen}
        setIsOpen={setIsDetailsOpen}
        lead={selectedLead}
        onActionComplete={handleActionComplete}
      />
    </div>
  )
}
