import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../config';

const MultiStepForm = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    userType: '',
    grade: '',
    helpNeeded: '',
    phone: '',
    email: '',
    notes: '',
  });
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const isStepValid = () => {
    switch (step) {
      case 1:
        return formData.userType !== '';
      case 2:
        return formData.grade !== '';
      case 3:
        return formData.helpNeeded !== '';
      case 4:
        return formData.phone !== '' && formData.email !== '';
      case 5:
        return true; // Notes are optional
      default:
        return false;
    }
  };

  const submitForm = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/user-form/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_type: formData.userType,
          grade: formData.grade,
          help_needed: formData.helpNeeded,
          phone: formData.phone,
          email: formData.email,
          notes: formData.notes,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to submit form');
      }

      // Form submitted successfully, move to the final step
      setStep(6);
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const nextStep = () => {
    if (isStepValid()) {
      if (step === 5) {
        submitForm();
      } else {
        setStep(step + 1);
      }
    }
  };

  const prevStep = () => setStep(step - 1);

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-4">Are you a student or a parent/guardian?</h2>
            <select
              name="userType"
              value={formData.userType}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-md"
            >
              <option value="">Select...</option>
              <option value="student">Student</option>
              <option value="parent">Parent/Guardian</option>
            </select>
          </div>
        );
      case 2:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-4">What is the grade level?</h2>
            <select
              name="grade"
              value={formData.grade}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-md"
            >
              <option value="">Select...</option>
              <option value="elementary">Elementary</option>
              <option value="middle">Middle</option>
              <option value="high">High</option>
              <option value="college">College</option>
              <option value="adult">Adult/Professional</option>
            </select>
          </div>
        );
      case 3:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-4">What do you need help with?</h2>
            <select
              name="helpNeeded"
              value={formData.helpNeeded}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-md"
            >
              <option value="">Select...</option>
              <option value="academics">Academics</option>
              <option value="testPrep">Test Prep</option>
              <option value="homework">Homework</option>
              <option value="project">Assignment/Project</option>
            </select>
          </div>
        );
      case 4:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-4">Contact Information</h2>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              placeholder="Phone Number"
              className="w-full p-2 mb-4 border border-gray-300 rounded-md"
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email Address"
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
        );
      case 5:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-4">Additional Notes</h2>
            <textarea
              name="notes"
              value={formData.notes}
              onChange={handleInputChange}
              placeholder="Please provide more details about your requirements (e.g., specific subjects, topics, or any other information)"
              rows="4"
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
        );
      case 6:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-4">Thank you!</h2>
            <p className="mb-4">Someone will reach out to you within a day.</p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full relative">
        <button
          onClick={() => navigate('/')}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        {renderStep()}
        <div className="mt-6 flex justify-between">
          {step > 1 && step < 6 && (
            <button
              onClick={prevStep}
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400"
            >
              Back
            </button>
          )}
          {step < 6 && (
            <button
              onClick={nextStep}
              className={`${
                isStepValid()
                  ? 'bg-indigo-600 hover:bg-indigo-500'
                  : 'bg-indigo-300 cursor-not-allowed'
              } text-white px-4 py-2 rounded-md ml-auto`}
              disabled={!isStepValid()}
            >
              {step === 5 ? 'Submit' : 'Next'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MultiStepForm;