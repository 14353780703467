  
export default function Course(props) {
  
  const courses = props.courses
  const title = props.title
  const description = props.description

  return (
    <div className="bg-white py-24 sm:py-32">
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{title}</h2>
        <p className="mt-2 text-lg leading-8 text-gray-600">{description}</p>
      </div>
      
      <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {courses.map((course) => (
            <article key={course.id} className="flex flex-col items-start justify-between bg-gray-200 rounded-xl hover:bg-blue-100">
              <div className="relative w-full p-4 ">
                <img
                  alt=""
                  src={course.imageUrl}
                  className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                />
              </div>


              <div className="max-w-xl p-4">
                  <h1>
                      <span className="mt-3 text-2xl font-semibold leading-6 text-gray-900">{course.title}</span>
                      <span className="mt-3 text-2xl font-normal leading-6 text-gray-900">{course.subtitle}</span>
                  </h1>
              </div>

              <div className="max-w-xl p-4">
                  <h2>
                      <span className="mt-3 text-lg font-normal leading-6 text-indigo-900">{course.duration}</span>
                  </h2>
              </div>

              <div className="max-w-xl p-4">
                  <p className="mt-3 text-lg font-normal leading-6 text-grey-900">{course.description}</p>
              </div>

              <div className="relative w-full flex items-center justify-between px-6 my-4">
                  <div className="mt-12 flex items-center gap-x-6 text-sm">
                      <div className="relative z-10 rounded-full bg-green-100 px-4 py-2 font-medium text-gray-600 hover:bg-gray-100">
                          {course.age}
                      </div>
                      <div className="relative z-10 rounded-full bg-green-100 px-4 py-2 font-medium text-gray-600 hover:bg-gray-100">
                          {course.level}
                      </div>
                  </div>
              </div>
              
              <div className="relative w-full flex items-center justify-between px-6 my-4">
                <a
                  href={course.path} // Replace with your desired URL or route
                  className="w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex items-center justify-center cursor-pointer"
                >
                  Explore Course
                </a>
              </div>


            </article>
          ))}
        </div>

    </div>
  </div>


  )
}
