import React, { useState, useEffect } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import tutorApi from './tutorApi';

// TODO :- Add api to delete when delete button is clicked against a degree

export default function Education() {
  const [degrees, setDegrees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const yearOptions = Array.from({ length: 51 }, (_, i) => 1990 + i);

  useEffect(() => {
    fetchEducationData();
  }, []);

  const fetchEducationData = async () => {
    try {
      setIsLoading(true);
      const data = await tutorApi.getTutorEducation();
      // console.log("Fetched education data:", data); // Log the fetched data

      if (data && Array.isArray(data) && data.length > 0) {
        const formattedDegrees = data.map((degree, index) => ({
          id: index + 1,
          degreeType: degree.degree,
          specialization: degree.degree_specialization,
          institute: degree.institute,
          graduationYear: degree.graduation_year ? degree.graduation_year.toString() : ''
        }));
        console.log("Formatted degrees:", formattedDegrees); // Log the formatted degrees
        setDegrees(formattedDegrees);
      } else {
        console.log("No degrees found, initializing with empty degree");
        setDegrees([{ id: 1 }]);
      }
    } catch (error) {
      console.error("Error fetching education data:", error);
      setError('Failed to fetch education data. Please try again.');
      setDegrees([{ id: 1 }]);
    } finally {
      setIsLoading(false);
    }
  };

  const addDegree = () => {
    const newId = Math.max(...degrees.map(d => d.id), 0) + 1;
    setDegrees(prevDegrees => [...prevDegrees, { id: newId }]);
  };

  const deleteDegree = (idToDelete) => {
    setDegrees(prevDegrees => prevDegrees.filter(degree => degree.id !== idToDelete));
  };

  const handleInputChange = (id, field, value) => {
    setDegrees(prevDegrees =>
      prevDegrees.map(degree =>
        degree.id === id ? { ...degree, [field]: value } : degree
      )
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const formattedDegrees = degrees.map(degree => ({
        degree: degree.degreeType,
        degree_specialization: degree.specialization,
        institute: degree.institute,
        graduation_year: parseInt(degree.graduationYear)
      }));
      await tutorApi.updateTutorEducation(formattedDegrees);
      // Show success message or handle successful update
    } catch (error) {
      setError('Failed to update education data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-red-600">{error}</div>;
  }

  console.log("Degrees in state:", degrees);

  return (
    <form onSubmit={handleSubmit} className="max-w-2xl mx-auto">
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Education Information</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Please provide details about your educational background.
          </p>

          {degrees.map((degree, index) => (
            <div key={degree.id} className="mt-10 space-y-6 bg-white rounded-lg p-6">
              <div className="flex justify-between items-center border-b border-gray-200 pb-4">
                <h3 className="text-lg font-bold leading-6 text-gray-900">Degree {index + 1}</h3>
                {degrees.length > 1 && (
                  <button
                    type="button"
                    onClick={() => deleteDegree(degree.id)}
                    className="text-red-600 hover:text-red-800"
                  >
                    <TrashIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                )}
              </div>
              <div className="w-full">
                <label htmlFor={`degree-${degree.id}`} className="block text-sm font-medium leading-6 text-gray-900">
                  Degree
                </label>
                <div className="mt-2">
                  <select
                    id={`degree-${degree.id}`}
                    name={`degree-${degree.id}`}
                    value={degree.degreeType || ''}
                    onChange={(e) => handleInputChange(degree.id, 'degreeType', e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value="">Select degree</option>
                    <option value="BACHELORS">Bachelors</option>
                    <option value="MASTERS">Masters</option>
                    <option value="DOCTORATE">Doctorate</option>
                    <option value="DIPLOMA">Diploma</option>
                    <option value="POST-DOCTORATE">Post Doctorate</option>
                  </select>
                </div>
              </div>

              <div className="w-full">
                <label htmlFor={`specialization-${degree.id}`} className="block text-sm font-medium leading-6 text-gray-900">
                  Specialization
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name={`specialization-${degree.id}`}
                    id={`specialization-${degree.id}`}
                    value={degree.specialization || ''}
                    onChange={(e) => handleInputChange(degree.id, 'specialization', e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="w-full">
                <label htmlFor={`institute-${degree.id}`} className="block text-sm font-medium leading-6 text-gray-900">
                  Institute/University
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name={`institute-${degree.id}`}
                    id={`institute-${degree.id}`}
                    value={degree.institute || ''}
                    onChange={(e) => handleInputChange(degree.id, 'institute', e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="w-full">
                <label htmlFor={`graduation-year-${degree.id}`} className="block text-sm font-medium leading-6 text-gray-900">
                  Year of Graduation
                </label>
                <div className="mt-2">
                  <select
                    id={`graduation-year-${degree.id}`}
                    name={`graduation-year-${degree.id}`}
                    value={degree.graduationYear || ''}
                    onChange={(e) => handleInputChange(degree.id, 'graduationYear', e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value="">Select year</option>
                    {yearOptions.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          ))}

          <div className="mt-6 flex items-center">
            <button
              type="button"
              onClick={addDegree}
              className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm ring-1 ring-inset ring-indigo-300 hover:bg-indigo-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Add Another Degree
            </button>
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end">
        <button
          type="submit"
          className="rounded-md bg-indigo-600 px-6 py-3 text-base font-semibold text-white shadow-lg hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition duration-150 ease-in-out transform hover:scale-105"
        >
          Save Education Details
        </button>
      </div>
    </form>
  );
}