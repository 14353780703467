import React, { useEffect } from 'react';
import Sidebar from './Sidebar';
import SubjectSelector from './SubjectSelector';
import BasicInfo from './BasicInfo';
import Education from './Education';
import BankDetails from './BankDetails';
import Contract from './Contract';
import Dashboard from './Dashboard';
import tutorApi from './tutorApi';
import Opportunity from './Opportunity';
import Earnings from './Earnings';
import Class from './Class';


function Home({ activeComponent }) {
  
  useEffect(() => {
    const fetchTutorInfo = async () => {
      try {
        const tutorInfo = await tutorApi.getTutorBasicInfo();
        // You can handle the tutorInfo here, e.g., update state or context
        //console.log('Tutor info:', tutorInfo);
      } catch (error) {
        console.error('Error fetching tutor info:', error);
      }
    };

    fetchTutorInfo();
  }, []);

  const renderComponent = () => {
    switch (activeComponent) {
      case 'SubjectSelector':
        return <SubjectSelector />;
      case 'BasicInfo':
        return <BasicInfo />;
      case 'Education':
        console.log('Inside Education')
        return <Education />;
      case 'BankDetails':
        return <BankDetails/>;
      case 'Opportunity':
        return <Opportunity/>
      case 'Earnings':
        return <Earnings/>
      case 'Class':
        return <Class/>
      // case 'Contract':
      //   return <Contract/>
      // Add more cases for other components
      default:
        return <Dashboard/>;
    }
  };

  return (
    <Sidebar activeComponent={activeComponent}>
      {renderComponent()}
    </Sidebar>
  );
}

export default Home;