import Header from "../../../common/Header";
import SubjectDetail from "../../../common/SubjectDetail";
import ReachAdvisorBanner from "../../../common/ReachAdvisorBanner";
import Footer from "../../../common/Footer";

import JavaScriptLevelA from "./JavascriptLevelA.json";
import JavaScriptLevelB from "./JavaScriptLevelB.json";

export default function WebDevelopment() {
    const courses = [JavaScriptLevelA, JavaScriptLevelB]
    const  content = {
        "title": "Learn Web Developement?",
        "description": "Web development involves creating websites and web applications by writing code in languages such as HTML, CSS, and JavaScript. These skills enable developers to build interactive and dynamic websites and applications that offer various functionalities and services to users across multiple online platforms and devices.",
        "backgroundColor": "#eaf7ff",
        "image": "web-dev.jpg",
        "courses": courses
    }
    const courseTitle = 'Start our Web development Program'
    const courseDescription = 'Web development is a great topic to study after you’ve learned the basics of coding. We recommend that your child start with JavaScript Level A. Previous coding experience is recommended for this topic'

    return (
        <>
            <Header/>
            <SubjectDetail content = {content} courseTitle={courseTitle} courseDescription={courseDescription}  />
            <ReachAdvisorBanner/>
            <Footer/>
        </>
    )
}