'use client'

// import { useState } from 'react'
import { StarIcon } from '@heroicons/react/20/solid'
import FreeClassBtn from './FreeClassBtn'

// const product = {
//   name: 'Everyday Ruck Snack',
//   href: '#',
//   price: '$220',
//   description:
//     "Don't compromise on snack-carrying capacity with this lightweight and spacious bag. The drawstring top keeps all your favorite chips, crisps, fries, biscuits, crackers, and cookies secure.",
//   imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-04-featured-product-shot.jpg',
//   imageAlt: 'Model wearing light green backpack with black canvas straps and front zipper pouch.',
//   breadcrumbs: [
//     { id: 1, name: 'Travel', href: '#' },
//     { id: 2, name: 'Bags', href: '#' },
//   ],
//   sizes: [
//     { name: '18L', description: 'Perfect for a reasonable amount of snacks.' },
//     { name: '20L', description: 'Enough room for a serious amount of snacks.' },
//   ],
// }

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function CourseDetail(props) {
  // const [selectedSize, setSelectedSize] = useState(product.sizes[0])
  const courseData = props.courseData

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
        {/* Course details */}
        <div className="lg:max-w-lg lg:self-end">
          {/* <nav aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-2">
              {product.breadcrumbs.map((breadcrumb, breadcrumbIdx) => (
                <li key={breadcrumb.id}>
                  <div className="flex items-center text-sm">
                    <a href={breadcrumb.href} className="font-medium text-gray-500 hover:text-gray-900">
                      {breadcrumb.name}
                    </a>
                    {breadcrumbIdx !== product.breadcrumbs.length - 1 ? (
                      <svg
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                        className="ml-2 h-5 w-5 flex-shrink-0 text-gray-300"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                    ) : null}
                  </div>
                </li>
              ))}
            </ol>
          </nav> */}

          <div className="mt-4">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{courseData.title}{courseData.subtitle}</h1>
          </div>

          <section aria-labelledby="information-heading" className="mt-4">
            <h2 id="information-heading" className="sr-only">
              Course information
            </h2>

            <div className="flex items-center">
              <p className="text-sm text-gray-500 sm:text-sm">Taken by {courseData.students} students</p>

              <div className="ml-4 border-l border-gray-300 pl-4">
                <h2 className="sr-only">Reviews</h2>
                <div className="flex items-center">
                  <div>
                    <div className="flex items-center">
                      {[0, 1, 2, 3, 4].map((rating) => (
                        <StarIcon
                          key={rating}
                          aria-hidden="true"
                          className={classNames(
                            courseData.reviews.average > rating ? 'text-yellow-400' : 'text-gray-300',
                            'h-5 w-5 flex-shrink-0',
                          )}
                        />
                      ))}
                    </div>
                    <p className="sr-only">{courseData.reviews.average} out of 5 stars</p>
                  </div>
                  <p className="ml-2 text-sm text-gray-500">{courseData.reviews.totalCount} reviews</p>
                  
                </div>
              </div>
            </div>

            <div className="mt-4 space-y-6">
              <p className="text-base text-gray-500">{courseData.description}</p>
            </div>

            {/* <div className="mt-6 flex items-center">
              <CheckIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-green-500" />
              <p className="ml-2 text-sm text-gray-500">In stock and ready to ship</p>
            </div> */}
          </section>
        </div>

        {/* Course image */}
        <div className="mt-10 lg:col-start-2 lg:row-span-2 lg:mt-0 lg:self-center shadow-lg">
            <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg">
                <img alt={courseData.imageAlt} src={`${process.env.PUBLIC_URL}/${courseData.imageUrl}`} className="h-full w-full object-cover object-center" />
            </div>
           
            <div className="p-4 bg-white rounded-lg flex items-center justify-between">
                <div className='flex items-center space-x-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6"><path fill-rule="evenodd" d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z" clip-rule="evenodd" /></svg>
                    <p>Course Duration</p>
                </div>
                <p>{courseData.duration}</p>
            </div>
            <div className="p-4 bg-white rounded-lg flex items-center justify-between">
                <div className='flex items-center space-x-2'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6"><path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 6a.75.75 0 0 0-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 0 0 0-1.5h-3.75V6Z" clip-rule="evenodd" /></svg>
                    <p>Lesson</p>
                </div>
                <p>{courseData.lesson}</p>
            </div>
            <div className="p-4 bg-white rounded-lg flex items-center justify-between">
                <div className='flex items-center space-x-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">  <path fill-rule="evenodd" d="m6.72 5.66 11.62 11.62A8.25 8.25 0 0 0 6.72 5.66Zm10.56 12.68L5.66 6.72a8.25 8.25 0 0 0 11.62 11.62ZM5.105 5.106c3.807-3.808 9.98-3.808 13.788 0 3.808 3.807 3.808 9.98 0 13.788-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788Z" clip-rule="evenodd" /></svg>
                    <p>Cancel Anytime</p>
                </div>
            </div>
        </div>





        {/* Product form */}
        <div className="mt-10 lg:col-start-1 lg:row-start-2 lg:max-w-lg lg:self-start">
          <section aria-labelledby="options-heading">
            <h2 id="options-heading" className="sr-only">
              Product options
            </h2>

            <form>
              <div className="sm:flex sm:justify-between">
                {/* Size selector */}
                {/* <fieldset>
                  <legend className="block text-sm font-medium text-gray-700">Size</legend>
                  <RadioGroup
                    value={selectedSize}
                    onChange={setSelectedSize}
                    className="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-2"
                  >
                    {product.sizes.map((size) => (
                      <Radio
                        key={size.name}
                        as="div"
                        value={size}
                        aria-label={size.name}
                        aria-description={size.description}
                        className="group relative block cursor-pointer rounded-lg border border-gray-300 p-4 focus:outline-none data-[focus]:ring-2 data-[focus]:ring-indigo-500"
                      >
                        <p className="text-base font-medium text-gray-900">{size.name}</p>
                        <p className="mt-1 text-sm text-gray-500">{size.description}</p>
                        <div
                          aria-hidden="true"
                          className="pointer-events-none absolute -inset-px rounded-lg border-2 border-transparent group-data-[focus]:border group-data-[checked]:border-indigo-500"
                        />
                      </Radio>
                    ))}
                  </RadioGroup>
                </fieldset> */}
              </div>
              {/* <div className="mt-4">
                <a href="#" className="group inline-flex text-sm text-gray-500 hover:text-gray-700">
                  <span>What size should I buy?</span>
                  <QuestionMarkCircleIcon
                    aria-hidden="true"
                    className="ml-2 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                  />
                </a>
              </div> */}
              <FreeClassBtn/>
              {/* <div className="mt-6 text-center">
                <a href="#" className="group inline-flex text-base font-medium">
                  <ShieldCheckIcon
                    aria-hidden="true"
                    className="mr-2 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                  />
                  <span className="text-gray-500 hover:text-gray-700">Lifetime Guarantee</span>
                </a>
              </div> */}
            </form>
          </section>
        </div>
      </div>
    </div>
  )
}
