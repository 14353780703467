import Footer from "../../../common/Footer"
import Header from "../../../common/Header"
import ReachAdvisorBanner from "../../../common/ReachAdvisorBanner"
import SubjectDetail from "../../../common/SubjectDetail"

import GeometryA from "../middle/GeometryA.json"
import GeometryB from "../middle/GeometryB.json"
import Algebra2A from "./Algebra2A.json"
import Algebra2B from "./Algebra2B.json"
import PreCalTrigA from "./PreCalTrigA.json"
import PreCalTrigB from "./PreCalTrigB.json"
import ApCalculus from "./ApCalculus.json"


export default function HighSchoolMath() {
    const courses = [GeometryA, GeometryB, Algebra2A, Algebra2B, PreCalTrigA, PreCalTrigB, ApCalculus]
    const content = {
        "title": "High School Math Courses",
        "description": "Our high school math courses are common-core aligned and designed to help students problem-solve, become more confident and enjoy learning math.",
        "backgroundColor": "#eaf7ff",
        "image": "sofatutor-4r5Hogjbgkw-unsplash.jpg",
        "courses": courses
    }
    const courseTitle = 'Our High School Level Math Courses'
    const courseDescription = 'Find the course that best aligns with where your child is in school. After your Advisor call, we’ll schedule a placement class to make sure your child is at the right level.'

    return (
        <>
            <Header/>
            <SubjectDetail content = {content} courseTitle={courseTitle} courseDescription={courseDescription}  />
            <ReachAdvisorBanner/>
            <Footer/>
        </>
    )
}