import { UserCircleIcon } from '@heroicons/react/24/solid'
import React, { useState, useEffect } from 'react';
import { TIMEZONES, TUTOR_BASIC_INFO } from '../../constants';
import tutorApi from './tutorApi';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { parse, format, isValid } from 'date-fns';

export default function BasicInfo() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    date_of_birth: null,
    email: '',
    country: '',
    city: '',
    state: '',  // Changed from 'region' to 'state'
    postal_code: '',  // Changed from 'postalCode' to 'postal_code'
    timezone: '',
    currency_for_payout: '',
    image_s3_key: ''
  });
  const [photo, setPhoto] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [photoUrl, setPhotoUrl] = useState('');

  useEffect(() => {
    const fetchTutorData = async () => {
      const tutorData = JSON.parse(localStorage.getItem(TUTOR_BASIC_INFO) || '{}');
      const updatedFormData = { ...formData };
      let hasFirstName = false;

      Object.keys(formData).forEach(key => {
        if (tutorData[key] && tutorData[key] !== '') {
          if (key === 'date_of_birth') {
            // Parse the date string from DD/MM/YYYY format
            const parsedDate = parse(tutorData[key], 'MM/dd/yyyy', new Date());
            console.log(tutorData[key]);
            console.log(parsedDate);
            // Only set the date if it's valid
            if (isValid(parsedDate)) {
              updatedFormData[key] = parsedDate;
            } else {
              updatedFormData[key] = null;
            }
          } else {
            updatedFormData[key] = tutorData[key];
          }
          if (key === 'firstName') {
            hasFirstName = true;
          }
        }
      });

      setFormData(updatedFormData);
      setIsReadOnly(hasFirstName);

      // If there's a image_s3_key in tutorData, generate a pre-signed URL
      if (tutorData.image_s3_key) {
        try {
          const presignedUrl = await tutorApi.getS3PresignedUrlForViewing(tutorData.image_s3_key);
          setPhotoUrl(presignedUrl);
        } catch (error) {
          console.error('Error fetching pre-signed URL:', error);
        }
      }
    };

    fetchTutorData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handlePhotoChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setPhoto(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      let photoUrl = '';
      if (photo) {
        const fileName = `${Date.now()}-${photo.name}`;
        const presignedUrl = await tutorApi.getS3PresignedUrl(fileName, photo.type);
        await tutorApi.uploadImageToS3(presignedUrl, photo);
        photoUrl = presignedUrl.split('?')[0]; // Remove query params to get the actual S3 URL
      }

      // console.log(formData.date_of_birth);

      const profileData = {
        ...formData,
        date_of_birth: formData.date_of_birth && isValid(formData.date_of_birth) 
          ? formData.date_of_birth//format(formData.date_of_birth, 'MM/dd/yyyy') 
          : null,
        photoUrl,
      };
      // console.log(formData.date_of_birth);
      // console.log(profileData.date_of_birth);

      await tutorApi.updateTutorProfile(profileData);
      // Handle success (e.g., show a success message, redirect, etc.)
    } catch (err) {
      setError(err.message || 'An error occurred while updating the profile');
    } finally {
      setLoading(false);
    }
  };

  // Function to format timezone for display
  const formatTimezone = (tz) => {
    const parts = tz.split('/');
    const city = parts[parts.length - 1].replace(/_/g, ' ');
    return `${city} (${tz})`;
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Basic Information</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            {isReadOnly 
              ? "Your basic information is displayed below. Contact support if you need to make changes."
              : "Please provide your basic information for your tutor profile. All fields are required."}
          </p>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="col-span-full">
              <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">
                Photo
              </label>
              <div className="mt-2 flex items-center gap-x-3">
                {photo ? (
                  <img src={URL.createObjectURL(photo)} alt="Profile" className="h-12 w-12 rounded-full object-cover" />
                ) : photoUrl ? (
                  <img src={photoUrl} alt="Profile" className="h-12 w-12 rounded-full object-cover" />
                ) : (
                  <UserCircleIcon className="h-12 w-12 text-gray-300" aria-hidden="true" />
                )}
                <input
                  type="file"
                  id="photo"
                  name="photo"
                  accept="image/*"
                  onChange={handlePhotoChange}
                  className="sr-only"
                />
                <label
                  htmlFor="photo"
                  className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 cursor-pointer"
                >
                  Change
                </label>
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-900">
                First name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  autoComplete="given-name"
                  required
                  value={formData.firstName}
                  onChange={handleInputChange}
                  readOnly={isReadOnly}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900">
                Last name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  autoComplete="family-name"
                  required
                  value={formData.lastName}
                  onChange={handleInputChange}
                  readOnly={isReadOnly}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="gender" className="block text-sm font-medium leading-6 text-gray-900">
                Gender
              </label>
              <div className="mt-2">
                <select
                  id="gender"
                  name="gender"
                  required
                  value={formData.gender}
                  onChange={handleInputChange}
                  disabled={isReadOnly}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  <option value="">Select gender</option>
                  <option value="MALE">Male</option>
                  <option value="FEMALE">Female</option>
                  <option value="OTHERS">Other</option>
                </select>
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="date_of_birth" className="block text-sm font-medium leading-6 text-gray-900">
                Date of Birth
              </label>
              <div className="mt-2">
                <DatePicker
                  selected={formData.date_of_birth}
                  onChange={(date) => setFormData({ ...formData, date_of_birth: date })}
                  dateFormat="MM/dd/yyyy"
                  required
                  readOnly={isReadOnly}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-4">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={formData.email}
                  onChange={handleInputChange}
                  readOnly={isReadOnly}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                Country
              </label>
              <div className="mt-2">
                <select
                  id="country"
                  name="country"
                  autoComplete="country-name"
                  required
                  value={formData.country}
                  onChange={handleInputChange}
                  disabled={isReadOnly}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  <option value="">Select country</option>
                  <option value="United States">United States</option>
                  <option value="Canada">Canada</option>
                  <option value="nited Kingdom">United Kingdom</option>
                  <option value="India">India</option>
                </select>
              </div>
            </div>

            <div className="sm:col-span-2 sm:col-start-1">
              <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                City
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="city"
                  id="city"
                  autoComplete="address-level2"
                  required
                  value={formData.city}
                  onChange={handleInputChange}
                  readOnly={isReadOnly}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="state" className="block text-sm font-medium leading-6 text-gray-900">
                State / Province
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="state"
                  id="state"
                  autoComplete="address-level1"
                  required
                  value={formData.state}
                  onChange={handleInputChange}
                  readOnly={isReadOnly}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="postal_code" className="block text-sm font-medium leading-6 text-gray-900">
                ZIP / Postal code
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="postal_code"
                  id="postal_code"
                  autoComplete="postal-code"
                  required
                  value={formData.postal_code}
                  onChange={handleInputChange}
                  readOnly={isReadOnly}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="timezone" className="block text-sm font-medium leading-6 text-gray-900">
                TimeZone for tutoring
              </label>
              <div className="mt-2">
                <select
                  id="timezone"
                  name="timezone"
                  required
                  value={formData.timezone}
                  onChange={handleInputChange}
                  disabled={isReadOnly}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  <option value="">Select timezone</option>
                  {TIMEZONES.map((tz) => (
                    <option key={tz} value={tz}>
                      {formatTimezone(tz)}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="currency_for_payout" className="block text-sm font-medium leading-6 text-gray-900">
                Currency for payout
              </label>
              <div className="mt-2">
                <select
                  id="currency_for_payout"
                  name="currency_for_payout"
                  required
                  value={formData.currency_for_payout}
                  onChange={handleInputChange}
                  disabled={isReadOnly}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  <option value="">Select currency</option>
                  <option value="USD">USD</option>
                  <option value="CAD">CAD</option>
                  {/* <option value="GBP">GBP</option> */}
                  <option value="INR">INR</option>
                  {/* Add more currency options */}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        {error && <p className="text-red-500">{error}</p>}
        {!isReadOnly && (
          <button
            type="submit"
            disabled={loading}
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {loading ? 'Saving...' : 'Save Changes'}
          </button>
        )}
      </div>
    </form>
  )
}