import Course from "./Course"
import FreeClassBtn from "./FreeClassBtn"

export default function SubjectDetail(props) {
    // console.log(props.content)
    const title = props.content.title
    const description = props.content.description
    const color = props.content.backgroundColor
    const image = props.content.image
    const courseTitle = props.courseTitle
    const courseDescription = props.courseDescription
    const courses = props.content.courses
    // console.log(color)
    
    return (
    <>
        <div className="bg-white py-24 sm:py-32" style={{ backgroundColor: color }}>
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div className="lg:pr-4">
                <div className="relative overflow-hidden rounded-3xl bg-white-900 px-6 pb-9 pt-64 shadow-2xl sm:px-12 lg:max-w-lg lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
                  <img
                    alt=""
                    src={`${process.env.PUBLIC_URL}/${image}`}
                    className="absolute inset-0 h-full w-full object-cover"
                  />
                  {/* <div className="absolute inset-0 bg-gray-900 mix-blend-multiply" /> */}
                  <div
                    aria-hidden="true"
                    className="absolute left-1/2 top-1/2 -ml-16 -translate-x-1/2 -translate-y-1/2 transform-gpu blur-3xl"
                  >
                    <div
                      style={{
                        clipPath:
                          'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                      }}
                      className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-40"
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
                  <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    {title}
                  </h1>
                  <div className="max-w-xl">
                    <p className="mt-6">
                      {description}
                    </p>

                    <FreeClassBtn/>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        

        <Course title={courseTitle} description={courseDescription} courses={courses}/>
    </>
      )
}

