import Header from "../../../common/Header";
import SubjectDetail from "../../../common/SubjectDetail";
import ReachAdvisorBanner from "../../../common/ReachAdvisorBanner";
import Footer from "../../../common/Footer";

import AiLevelA from "../python/AiLevelA.json";
import AiLevelB from "../python/AiLevelB.json";


export default function AI() {
    const courses = [AiLevelA, AiLevelB]
    const  content = {
        "title": "Learn AI?",
        "description": "Learning to code artificial intelligence (AI) equips students with highly valuable, future-proof skills that are essential across various industries, including technology, business, and healthcare. By mastering AI programming, students enhance their logical thinking, boost their employability, and gain the ability to create impactful applications such as computer vision, speech recognition, and predictive analytics. This foundational knowledge in AI not only prepares them for high-demand careers but also empowers them to drive innovation in a rapidly evolving digital landscape. ",
        "backgroundColor": "#eaf7ff",
        "image": "ai.jpg",
        "courses": courses
    }
    const courseTitle = 'Start Our AI Program'
    const courseDescription = 'These courses are a great option if your student is very comfortable with programming in Python. All students should start with AI Level A, but feel free to look at AI Level B to explore the concepts ahead.  '

    return (
        <>
            <Header/>
            <SubjectDetail content = {content} courseTitle={courseTitle} courseDescription={courseDescription}  />
            <ReachAdvisorBanner/>
            <Footer/>
        </>
    )
}