import React, { useState, useEffect } from 'react';
import { getPaymentHistory } from './tutorApi';

const Earnings = () => {
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const paymentsData = await getPaymentHistory();
        setPaymentHistory(paymentsData);
      } catch (err) {
        setError('Failed to load data.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div className="container mx-auto p-4 text-center">Loading...</div>;
  }

  if (error) {
    return <div className="container mx-auto p-4 text-center text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Payment History</h1>

      {paymentHistory.length > 0 ? (
        <table className="min-w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="border p-2">Payment Date</th>
              <th className="border p-2">Amount</th>
              <th className="border p-2">Payment Method</th>
              <th className="border p-2">Transaction ID</th>
              <th className="border p-2">Status</th>
            </tr>
          </thead>
          <tbody>
            {paymentHistory.map((payment, index) => (
              <tr key={index} className="hover:bg-gray-50">
                <td className="border p-2">{payment.paymentDate}</td>
                <td className="border p-2">
                  {payment.amount} {payment.currency}
                </td>
                <td className="border p-2">{payment.method}</td>
                <td className="border p-2">{payment.reference}</td>
                <td className="border p-2 text-green-500">{payment.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="text-gray-500">No payment history available.</p>
      )}
    </div>
  );
};

export default Earnings;
