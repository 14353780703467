import React, { useState, useEffect } from 'react';
import tutorApi from './tutorApi';


const DemoList = () => {
  const [upcomingDemos, setUpcomingDemos] = useState([]);
  const [completedDemos, setCompletedDemos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDemos = async () => {
      try {
        setLoading(true);
        const demos = await tutorApi.getDemos();
        setUpcomingDemos(demos.Upcoming);
        setCompletedDemos(demos.Completed);
      } catch (err) {
        setError('Failed to load demo data.');
      } finally {
        setLoading(false);
      }
    };

    fetchDemos();
  }, []);

  if (loading) {
    return <div className="container mx-auto p-4 text-center">Loading...</div>;
  }

  if (error) {
    return <div className="container mx-auto p-4 text-center text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Upcoming Demos</h1>
      {upcomingDemos && upcomingDemos.length > 0 ? (
        upcomingDemos.map((demo, index) => (
          <div key={index} className="bg-white shadow-md rounded-lg p-4 mb-4">
            <h2 className="text-xl font-semibold">{demo.subject}</h2>
            <p className="text-sm text-gray-600 mb-1">Demo Time: {demo.dateTime}</p>
            <p className="text-sm text-gray-600 mb-1">Grade: {demo.grade}</p>
            {/* <p className="text-sm text-gray-600 mb-1">Country: {demo.country}</p> */}
            <p className="text-sm text-gray-600 mb-1">Topic: {demo.topic}</p>
            {/* <p className="text-sm text-gray-600 mb-2">Notes: {demo.notes}</p> */}
            <p className="text-sm text-gray-600 mb-2">
              <span className="font-semibold">Demo Link: </span>
              <a
                href={demo.meetingLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                {demo.meetingLink}
              </a>
            </p>
            <div className="text-sm text-gray-600 mb-2">
              <span className="font-semibold">Attachments: </span>
              {demo.attachments.length > 0 ? (
                <ul className="list-disc list-inside">
                  {demo.attachments.map((attachment, i) => (
                    <li key={i} className="text-blue-500 hover:underline">
                      {attachment}
                    </li>
                  ))}
                </ul>
              ) : (
                <span>None</span>
              )}
            </div>
          </div>
        ))
      ) : (
        <p className="text-gray-600">No upcoming demos available.</p>
      )}

      <h1 className="text-2xl font-bold mt-6 mb-4">Completed Demos</h1>
      {completedDemos && completedDemos.length > 0 ? (
        completedDemos.map((demo, index) => (
          <div key={index} className="bg-gray-100 shadow-md rounded-lg p-4 mb-4">
            <h2 className="text-xl font-semibold">{demo.subject}</h2>
            <p className="text-sm text-gray-600 mb-1">Demo Time: {demo.demoTime}</p>
            <p className="text-sm text-gray-600 mb-1">Grade: {demo.grade}</p>
            {/* <p className="text-sm text-gray-600 mb-1">Country: {demo.country}</p> */}
            <p className="text-sm text-gray-600 mb-1">Topic: {demo.demoTopic}</p>
            {/* <p className="text-sm text-gray-600 mb-2">Notes: {demo.notes}</p> */}
            <p className="text-sm text-gray-600 mb-2">
              <span className="font-semibold">Demo Link: </span>
              <a
                href={demo.meetingLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                {demo.meetingLink}
              </a>
            </p>
            <div className="text-sm text-gray-600 mb-2">
              <span className="font-semibold">Attachments: </span>
              {demo.attachments.length > 0 ? (
                <ul className="list-disc list-inside">
                  {demo.attachments.map((attachment, i) => (
                    <li key={i} className="text-blue-500 hover:underline">
                      {attachment}
                    </li>
                  ))}
                </ul>
              ) : (
                <span>None</span>
              )}
            </div>
          </div>
        ))
      ) : (
        <p className="text-gray-600">No completed demos available.</p>
      )}
    </div>
  );
};

export default DemoList;
