import React from 'react';
import CreateInvoiceForm from './CreateInvoiceForm';

const InvoiceDialog = ({ isOpen, onClose, students, onSubmit, editData = null }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">
            {editData ? 'Edit Invoice' : 'Create New Invoice'}
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <CreateInvoiceForm 
          students={students}
          onSubmit={(formData) => {
            onSubmit(formData);
            onClose();
          }}
          initialData={editData}
        />
      </div>
    </div>
  );
};

export default InvoiceDialog; 