import Header from "../../../common/Header";
import SubjectDetail from "../../../common/SubjectDetail";
import ReachAdvisorBanner from "../../../common/ReachAdvisorBanner";
import Footer from "../../../common/Footer";

import JavaLevelA from "./JavaLevelA.json";
import JavaLevelB from "./JavaLevelB.json";
import JavaLevelC from "./JavaLevelC.json";

export default function Java() {
    const courses = [JavaLevelA, JavaLevelB, JavaLevelC]
    const  content = {
        "title": "What is Java?",
        "description": "Java is an object-oriented programming language that is good for writing modular and reusable code. Java is easy to learn and a great introductory language for newer and intermediate coders.",
        "backgroundColor": "#eaf7ff",
        "image": "java.jpg",
        "courses": courses
    }
    const courseTitle = 'Start our Java Program'
    const courseDescription = 'Pick your course based on your student’s age and experience level. '

    return (
        <>
            <Header/>
            <SubjectDetail content = {content} courseTitle={courseTitle} courseDescription={courseDescription}  />
            <ReachAdvisorBanner/>
            <Footer/>
        </>
    )
}