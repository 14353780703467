import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchOnboardingStatus } from './tutorApi';
import DemoList from './DemoList';

function Dashboard() {
    const [onboardingComplete, setOnboardingComplete] = useState(false);
    const [completedSteps, setCompletedSteps] = useState({});

    useEffect(() => {
        const checkOnboardingStatus = async () => {
            try {
                const status = await fetchOnboardingStatus();
                setOnboardingComplete(status.isComplete);
                setCompletedSteps(status.completedSteps);
            } catch (error) {
                console.error('Failed to fetch onboarding status:', error);
                // Handle error (e.g., show an error message to the user)
            }
        };
        checkOnboardingStatus();
    }, []);

    const getStepStatus = (step) => completedSteps[step] ? '✅' : '❌';

    return (
        <>
            <h1>Welcome to dashboard</h1>
            {onboardingComplete ? (
                <div style={{
                    backgroundColor: '#D4EDDA',
                    color: '#155724',
                    padding: '15px',
                    borderRadius: '5px',
                    marginBottom: '20px'
                }}>
                    <strong>Congratulations! Your onboarding is complete.</strong>
                    <p>You will now start receiving information about new tutoring opportunities on this platform.</p>
                    <p>We'll also send you email notifications to inform you about new opportunities.</p>
                </div>
            ) : (
                <div style={{
                    backgroundColor: '#FFF3CD',
                    color: '#856404',
                    padding: '15px',
                    borderRadius: '5px',
                    marginBottom: '20px'
                }}>
                    <strong>Complete your onboarding!</strong> Please complete all sections in the Onboarding tab:
                    <ul>
                        <li>{getStepStatus('basic_info')} Basic Info</li>
                        <li>{getStepStatus('subject_selection')} Subject Selection</li>
                        <li>{getStepStatus('degree')} Education</li>
                        <li>{getStepStatus('bank_details')} Bank Details</li>
                    </ul>
                    <Link to="/tutor/onboarding/basicinfo" style={{ color: '#856404', fontWeight: 'bold' }}>
                        Go to Onboarding
                    </Link>
                </div>
            )}
            {/* Display other tutor data as needed */}
            <DemoList/>
        </>
    );
}

export default Dashboard;
