import React, { useState, useEffect } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import tutorApi from './tutorApi';

export default function SubjectSelector() {
  const [subjects, setSubjects] = useState({});
  const [selectedSubjects, setSelectedSubjects] = useState({});
  const [openSubjects, setOpenSubjects] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    fetchSubjects();
  }, []);

  const fetchSubjects = async () => {
    try {
      setIsLoading(true);
      const response = await tutorApi.getTutorSubjects();
      setSubjects(response);
      initializeSelectedSubjects(response);
      setIsLoading(false);
    } catch (err) {
      setError('Failed to fetch subjects. Please try again later.');
      setIsLoading(false);
    }
  };

  const initializeSelectedSubjects = (subjectsData) => {
    const initialSelected = {};
    Object.entries(subjectsData).forEach(([mainSubject, subTopics]) => {
      initialSelected[mainSubject] = {};
      subTopics.forEach(subTopic => {
        initialSelected[mainSubject][subTopic.id] = subTopic.eligible === "true";
      });
    });
    setSelectedSubjects(initialSelected);
  };

  const handleCheckboxChange = (mainSubject, subTopicId) => {
    setSelectedSubjects(prev => ({
      ...prev,
      [mainSubject]: {
        ...prev[mainSubject],
        [subTopicId]: !prev[mainSubject][subTopicId]
      }
    }));
  };

  const toggleSubject = (mainSubject) => {
    setOpenSubjects(prev => ({
      ...prev,
      [mainSubject]: !prev[mainSubject]
    }));
  };

  const handleSubmit = async () => {
    try {
      const selectedSubjectIds = Object.entries(selectedSubjects).flatMap(([mainSubject, subTopics]) =>
        Object.entries(subTopics)
          .filter(([id, isSelected]) => isSelected)
          .map(([id]) => parseInt(id))
      );

      await tutorApi.updateTutorSubjects(selectedSubjectIds);
      setSuccessMessage('Subjects updated successfully');
      // Hide the success message after 3 seconds
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (error) {
      console.error('Failed to update subjects:', error);
      setError('Failed to update subjects. Please try again.');
      // Hide the error message after 3 seconds
      setTimeout(() => setError(null), 3000);
    }
  };

  if (isLoading) return <div>Loading subjects...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="p-6">
      {successMessage && (
        <div className="mb-4 p-4 bg-green-100 border border-green-400 text-green-700 rounded">
          {successMessage}
        </div>
      )}
      {error && (
        <div className="mb-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
          {error}
        </div>
      )}
      <h2 className="text-2xl font-bold mb-4">Select Your Subjects</h2>
      {Object.entries(subjects).map(([mainSubject, subTopics]) => (
        <div key={mainSubject} className="mb-4">
          <button
            onClick={() => toggleSubject(mainSubject)}
            className="flex items-center justify-between w-full text-left text-xl font-semibold bg-gray-100 p-3 rounded-lg hover:bg-gray-200 transition-colors duration-200"
          >
            <span>{mainSubject}</span>
            {openSubjects[mainSubject] ? (
              <ChevronUpIcon className="h-5 w-5" />
            ) : (
              <ChevronDownIcon className="h-5 w-5" />
            )}
          </button>
          {openSubjects[mainSubject] && (
            <div className="mt-2 ml-4 grid grid-cols-2 gap-2">
              {subTopics.map(subTopic => (
                <label key={subTopic.id} className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={selectedSubjects[mainSubject]?.[subTopic.id] || false}
                    onChange={() => handleCheckboxChange(mainSubject, subTopic.id)}
                    className="form-checkbox h-5 w-5 text-indigo-600"
                  />
                  <span>{subTopic.subject}</span>
                </label>
              ))}
            </div>
          )}
        </div>
      ))}
      <button
        onClick={handleSubmit}
        className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Submit
      </button>
    </div>
  );
}